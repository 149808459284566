import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'website',
      category: 'website',
      default: true,
      defaultRoute: '/website/overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'scorecard',
          displayName: 'Activity',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'referrer',
          displayName: 'Referrer',
          iconClass: 'fa fa-share',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          roles: ['System Administrator', 'Corporate', "System Analyst"]
        }
      ]
    },
    // {
    //   id: 'programOverview',
    //   category: 'programOverview',
    //   default: true,
    //   defaultRoute: '/program-overview/program-overview',
    //   displayName: 'Program Overview',
    //   displayInNav: true,
    //   iconClass: 'fa fa-server',
    //   roles: undefined,
    //   children: [
    //     {
    //       parent: '/program-overview',
    //       route: 'program-overview',
    //       displayName: 'Program Overview',
    //       pdfEnabled: true,
    //       breadcrumbHelpEnabled: true,
    //       iconClass: 'fa fa-server',
    //       roles: undefined
    //     }]
    // },
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/forecasting',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'forecasting',
          displayName: 'Overview',
          pdfEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: true,
      defaultRoute: '/provider-tools/digital-retailing',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-wrench',
      roles: undefined,
      children: [
      {
        parent: '/provider-tools',
        route: 'chat-overview',
        displayName: 'Chat',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-users',
        roles: undefined
      },
      {
        parent: '/provider-tools',
        route: 'digital-retailing',
        displayName: 'Digital Retailing',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-users',
        roles: undefined
      },
      {
        parent: '/provider-tools',
        route: 'service-scheduler',
        displayName: 'Service Scheduler',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-exchange',
        roles: undefined
      },
      {
        parent: '/provider-tools',
        route: 'trade-in',
        displayName: 'Trade-In',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-exchange',
        roles: undefined
      }]
    },
    {
      id: 'user-activity',
      category: 'user-activity',
      default: true,
      defaultRoute: '/user-activity/overview',
      displayName: 'User Activity',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
        parent: '/user-activity',
        route: 'overview',
        displayName: 'Overview',
        pdfEnabled: true,
        iconClass: 'fa fa-users',
        breadcrumbHelpEnabled: true,
        roles: ['System Administrator', "System Analyst"],
      }]
    },
    {
      id: 'resources',
      category: 'resources',
      defaultRoute: '/resources/resources',
      displayName: 'Dealer Resources',
      displayInNav: true,
      iconClass: 'fa fa-file',
      roles: undefined,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/resources',
          route: 'resources',
          displayName: 'Dealer Resources',
          iconClass: 'fa fa-file',
          roles: undefined,
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
          parent: '/management',
          route: 'provider',
          displayName: 'Providers',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst", "Corporate", "Dealer"],
          children:
          [
          ]
        },
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"]
        }, {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'filter-configuration',
          displayName: 'Filters',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'filter-configuration/create',
              parent: 'filter-configuration',
              route: 'create',
              displayName: 'Create Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'filter-configuration/update',
              parent: 'filter-configuration',
              route: 'update',
              displayName: 'Edit Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
        }
      ]
    }
  ];
}
